import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

// intl-tel-input depends on this for most formatting, validation, etc.
// It loads async from this URL if a tel input is on the page, since it's BIG.
const utilsScript = `https://cdn.jsdelivr.net/npm/intl-tel-input@${intlTelInput.version}/build/js/utils.js`;

function enablePhoneFields(scope) {
  scope.querySelectorAll('input[type="tel"]').forEach((field) => {
    const telInput = intlTelInput(field, {
      allowDropdown: false,
      onlyCountries: ['us'],
      utilsScript
    });

    field.addEventListener('input', validatePhoneField);
    telInput.promise
      .then(() => validatePhoneField({ target: field }))
      .catch((error) => console.error(`Could not load intl-tel-input: ${error}`));
  });
}

function validatePhoneField (event) {
  const telInput = intlTelInput.getInstance(event.target);
  if (event.target.value && telInput?.isValidNumber() === false) {
    event.target.setCustomValidity('Please enter a valid US phone number, including area code. For example: “(311) 555-2368”.');
  } else {
    event.target.setCustomValidity('');
  }
}

document.addEventListener('turbolinks:load', () => {
  enablePhoneFields(document.body);
});

document.addEventListener('sprinkles:update', (event) => {
  enablePhoneFields(event.target);
});
