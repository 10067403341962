/**
 * Support TinyMCE in combination with Turbolinks.
 *
 * Because we only load the TinyMCE library on pages where it is used,
 * Turbolinks can create some messy race conditions and other issues when it
 * places new content on the page. The `<script src="...">` elements that load
 * the library are async instead of blocking in this case, so we need to wait
 * for them to load before initializing any editors.
 *
 * This module handles these situations, and assumes that TinyMCE will be loaded
 * via `<script src="...">` elements directly on the page.
 *
 * NOTE: this is partly caused by the fact that we use the `tinymce-rails` gem,
 * which outputs TinyMCE as a separate JS script as part of the asset pipeline
 * and includes it via ERB template tags, instead of including TinyMCE in
 * `package.json` and loading it async here.
 */

/**
 * Initialize any TinyMCE editors on the page if TinyMCE is available. If
 * TinyMCE is not available, this returns `false` and does nothing.
 * @returns {boolean} Whether TinyMCE initialization was completed.
 */
function initializeTinyMce() {
  if (typeof TinyMCERails !== 'undefined') {
    TinyMCERails.configuration.default = {
      selector: "textarea.tinymce",
      cache_suffix: `?v=${tinymce.majorVersion}.${tinymce.minorVersion}`,
      plugins: "image,link,table,wordcount"
    };
    TinyMCERails.initialize('default', {});
    return true;
  }
  return false;
}

/**
 * Destroy any editors on outgoing pages to avoid memory leaks.
 */
function handleTurbolinksBeforeRender () {
  if (typeof tinymce !== 'undefined') {
    tinymce.remove();
  }
}

/**
 * Handle page load events by finding and initializing any new editors on the
 * page. If the TinyMCE library has not yet loaded but is on the new page, wait
 * for it to load, then try again.
 *
 * This is safe to call multiple times. TinyMCE will not double-init an
 * existing editor.
 */
function handlePageLoad (event) {
  // If editors can't be initialized, look for editor scripts and try to
  // initialize after they load (or fail).
  //
  // This handles the fact that we load TinyMCE via the `tinymce-rails` gem,
  // which places the TinyMCE script directly on the pages we use it in,
  // rather than loading via an async `import()` call here.
  if (!initializeTinyMce()) {
    const tinyMceScripts = Array.from(document.querySelectorAll('script[src*="tinymce"]'));
    let remaining = tinyMceScripts.length;
    if (!remaining) return;

    function loadHandler (event) {
      event.target.removeEventListener('load', loadHandler, false);
      event.target.removeEventListener('error', loadHandler, false);
      remaining--;

      if (remaining <= 0) {
        initializeTinyMce()
      }
    }

    for (const script of tinyMceScripts) {
      script.addEventListener('load', loadHandler, false);
      script.addEventListener('error', loadHandler, false);
    }
  }
}

/**
 * Actually hook TinyMCE functionality into Turbolinks and start TinyMCE.
 */
export function start () {
  document.addEventListener('turbolinks:before-render', handleTurbolinksBeforeRender);
  document.addEventListener('turbolinks:load', handlePageLoad);
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handlePageLoad);
  } else {
    handlePageLoad();
  }
}

start();
